import { render, staticRenderFns } from "./registerDetail.vue?vue&type=template&id=41db81ab&scoped=true&"
import script from "./registerDetail.vue?vue&type=script&lang=js&"
export * from "./registerDetail.vue?vue&type=script&lang=js&"
import style0 from "./registerDetail.vue?vue&type=style&index=0&id=41db81ab&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41db81ab",
  null
  
)

export default component.exports